import React, {
  useLayoutEffect,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import { useAccount } from '@azure/msal-react';
import {
  useMembershipApiService,
  useStaffListApiService,
} from "../services";
import { showError } from '../helpers/ui';

const GlobalStateContext = createContext({});
const useGlobalState = () => useContext(GlobalStateContext);

function GlobalStateProvider(props) {
  const [lookupData, setLookupData] = useState({});
  const [managedSchools, setManagedSchools] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();
  const { getLookupsAsync } = useMembershipApiService();
  const { getSchoolsForWhichUserIsACommRepAsync } = useStaffListApiService();
  const user = useAccount();

  useLayoutEffect(() => {
    (async () => {
      if (user) {
        try {
          const r = await getSchoolsForWhichUserIsACommRepAsync();
          if (r.data) {
            setManagedSchools(r.data);
          } else {
            showError('Error getting schools for which the signed on user is a communication rep.')
          }
        }
        catch (error) {
          showError(error.message);
        }
      }
    })();
  }, [
    setManagedSchools,
    getSchoolsForWhichUserIsACommRepAsync,
    user
  ]);

  useEffect(() => {
    (async () => {
      let lookupData = {};
      const getOps = [
        getLookupsAsync('GetSalutations').then((response) => lookupData.salutations = response.data.data),
        getLookupsAsync('GetGenders').then((response) => lookupData.genders = response.data.data),
        getLookupsAsync('GetPronouns').then((response) => lookupData.pronouns = response.data.data),
        getLookupsAsync('GetEmailAddressTypes').then((response) => lookupData.emailAddressTypes = response.data.data),
        getLookupsAsync('GetPhoneNumberTypes').then((response) => lookupData.phoneNumberTypes = response.data.data),
        getLookupsAsync('GetSchools').then((response) => lookupData.schools = response.data.data),
        getLookupsAsync('GetDivisions').then((response) => lookupData.divisions = response.data.data),
        getLookupsAsync('GetDesignations').then((response) => lookupData.designations = response.data.data),
        getLookupsAsync('GetProvinces').then((response) => lookupData.provinces = response.data.data),
      ];
      await Promise.all(getOps);
      setLookupData(lookupData);
    })();
  }, [
    getLookupsAsync,
    setLookupData
  ]);

  return (
    <GlobalStateContext.Provider
      value={{
        lookupData,
        setLookupData,
        managedSchools,
        setManagedSchools,
        selectedSchool,
        setSelectedSchool,
        selectedMembers,
        setSelectedMembers,
      }}
      {...props}
    />
  );
}

export {
  GlobalStateProvider,
  useGlobalState,
};

