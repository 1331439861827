import React, {
	useState,
	useLayoutEffect,
} from 'react';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { TextBox as TB, Button as TextBoxButton } from 'devextreme-react/text-box';
import TextBox from "devextreme/ui/text_box";
import Button from 'devextreme-react/button';
import List from 'devextreme-react/list';
import {
	Validator,
	PatternRule
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import { appConst } from '../../app-const';
import { useStaffListApiService } from '../../services';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import '../../components/component.scss';
import './add-staff-request.scss';
import { showError, showSuccess } from '../../helpers/ui';

export default function () {
	const [selectedMembers, setSelectedMembers] = useState([]);
	const { findMemberByNameAsync, requestSchoolAssociationAdditionAsync } = useStaffListApiService();
	const { selectedSchool } = useGlobalState();
	const { setTitle } = usePageTitle();

	useLayoutEffect(() => setTitle("Add Staff"), [setTitle]);

	function autoCompleteItemRender(item) {
		return (
			<div className="item-container">
				<div>{item.fullName}</div>
				<div className="push-to-right">{item.primarySchool}</div>
			</div>
		);
	}

	function renderMember(item) {
		return (
			<div className="item-container" key={`Member${item.memberId}`}>
				<p id={`memberName${item.memberId}`}>{item.hasOwnProperty('fullName') ? item.fullName : item.emailAddress}</p>
				<Button
					id="removeMember"
					className="push-to-right"
					icon="trash"
					name={item.hasOwnProperty('fullName') ? item.id : item.emailAddress}
					onClick={
						(e) => {
							let tmp = [...selectedMembers];
							let index;
							if (!isNaN(e.component.option('name'))) {
								let id = parseInt(e.component.option('name'));
								index = tmp.findIndex(obj => obj.id === id);
							} else {
								let email = e.component.option('name');
								index = tmp.findIndex(obj => obj.emailAddress === email);
							}
							tmp.splice(index, 1);
							setSelectedMembers(tmp);
						}
					}
				/>
			</div>
		);
	}

	return (
		<div className="responsive-paddings">
			<p id="message1">{"Enter email address that you want to send the sign-up link to, one per line:"}</p>
			<Autocomplete
				id="memberAutoComplete"
				dataSource={findMemberByNameAsync()}
				valueExpr="fullName"
				searchExpr={["PreferredFirstName", "FirstName", "LastName"]}
				maxItemCount={250}
				placeholder="Type Name..."
				itemRender={autoCompleteItemRender}
				onSelectionChanged={(e) => {
					if (null !== e.selectedItem) {
						let tmp = [...selectedMembers];
						tmp.push(e.selectedItem);
						setSelectedMembers(tmp);
						e.component.reset();
					}
				}}
			>
			</Autocomplete>
			<p id="message2">{"If you can't find a member by searching above (for example, if the staff member hasn't worked in Edmonton Public before), you can type their email address below."}</p>
			<ValidationGroup>
				<TB
					id="emailTextBox"
				>
					<TextBoxButton
						name="addEmail"
						location="after"
						options={
							{
								icon: 'add',
								type: 'default',
								onClick: (e) => {
									let el = document.getElementById("emailTextBox");
									let tb = TextBox.getInstance(el);
									let emailTxt = tb.option('value');
									if ('' === emailTxt || !tb.option("isValid")) return;
									tb.reset();

									let tmp = [...selectedMembers];
									const email = {
										emailAddress: emailTxt
									}
									tmp.push(email);
									setSelectedMembers(tmp);
								}
							}
						}
					/>
					<Validator>
						<PatternRule
							pattern={appConst.emailPattern}
							message={appConst.emailValidationMessage}
						/>
					</Validator>
				</TB>
			</ValidationGroup>
			<Button
				className="send-button"
				id="send"
				type="default"
				text="Send"
				onClick={
					async (e) => {
						if (selectedMembers.length === 0) {
							showError('There is no data to send.');
							return;
						}
						let members = selectedMembers.filter(function (e) {
							if (e.hasOwnProperty('fullName')) {
								return e;
							} else {
								return null;
							}
						}).map(a => a.id);
						let emails = selectedMembers.filter(function (e) {
							if (e.hasOwnProperty('emailAddress')) {
								return e;
							} else {
								return null;
							}
						}).map(a => a.emailAddress);
						try {
							e.component.option("disabled", true);
							const r = await requestSchoolAssociationAdditionAsync(selectedSchool.schoolId, members, emails);
							if (r.data) {
								showSuccess('Email has been sent.')
							} else {
								showError('There was an error sending the data.')
							}
							e.component.option("disabled", false);
						} catch (error) {
							showError(error.message)
							e.component.option("disabled", false);
						}
					}
				}
			/>
			<List
				id="memberList"
				dataSource={selectedMembers}
				showSelectionControls={false}
				searchEnabled={false}
				itemRender={renderMember}
				scrollingEnabled={false}
				useNativeScrolling={false}
			/>
		</div>
	);
}